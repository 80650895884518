import { getNavigationResources } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { LegacyPage } from '@atlassian/jira-router-routes-common/src/ui/legacy-page/main.tsx';
import { globalSettingsIssuesExplicitFieldAssociationRouteEntry } from '@atlassian/jira-router-routes-global-settings-issues-explicit-field-association-entries/src/globalSettingsIssuesExplicitFieldAssociationRouteEntry.tsx';
import { addonsEmceePublicRouteEntry } from '@atlassian/jira-router-routes-legacy-addons-emcee-public-entries/src/addonsEmceePublicRouteEntry.tsx';
import { legacyAllReportsRouteEntry } from '@atlassian/jira-router-routes-legacy-all-reports-entries/src/legacyAllReportsRouteEntry.tsx';
import { legacyBacklogRouteEntry } from '@atlassian/jira-router-routes-legacy-backlog-entries/src/legacyBacklogRouteEntry.tsx';
import { legacyBoardRapidViewRouteEntry } from '@atlassian/jira-router-routes-legacy-board-entries/src/legacyBoardRapidViewRouteEntry.tsx';
import { legacyBoardRouteEntry } from '@atlassian/jira-router-routes-legacy-board-entries/src/legacyBoardRouteEntry.tsx';
import { legacyConnectGeneralRouteEntry } from '@atlassian/jira-router-routes-legacy-connect-general-entries/src/legacyConnectGeneralRouteEntry.tsx';
import { legacyConnectProjectRouteEntry } from '@atlassian/jira-router-routes-legacy-connect-project-entries/src/legacyConnectProjectRouteEntry.tsx';
import { legacyCustomReportRouteEntry } from '@atlassian/jira-router-routes-legacy-custom-report-entries/src/legacyCustomReportRouteEntry.tsx';
import { legacyDashboardsAddRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-add-entries/src/legacyDashboardsAddRouteEntry.tsx';
import { legacyDashboardsConfigureRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-configure-entries/src/legacyDashboardsConfigureRouteEntry.tsx';
import { legacyDashboardsDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-delete-entries/src/legacyDashboardsDeleteRouteEntry.tsx';
import { dashboardsDirectoryRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-directory-entries/src/dashboardsDirectoryRouteEntry.tsx';
import { legacyDashboardsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-edit-entries/src/legacyDashboardsEditRouteEntry.tsx';
import { legacyDashboardsRestoreRouteEntry } from '@atlassian/jira-router-routes-legacy-dashboards-restore-entries/src/legacyDashboardsRestoreRouteEntry.tsx';
import { legacyFallbackRouteEntry } from '@atlassian/jira-router-routes-legacy-fallback-entries/src/legacyFallbackRouteEntry.tsx';
import { legacyGinActionRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-action-entries/src/legacyGinActionRouteEntry.tsx';
import { legacyGinBulkRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-bulk-entries/src/legacyGinBulkRouteEntry.tsx';
import { legacyGinBulkeditRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-bulkedit-entries/src/legacyGinBulkeditRouteEntry.tsx';
import { legacyGinIssueAssignRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-assign-entries/src/legacyGinIssueAssignRouteEntry.tsx';
import { legacyGinIssueCloneRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-clone-entries/src/legacyGinIssueCloneRouteEntry.tsx';
import { legacyGinIssueConvertRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-convert-entries/src/legacyGinIssueConvertRouteEntry.tsx';
import { legacyGinIssueCreateRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-create-entries/src/legacyGinIssueCreateRouteEntry.tsx';
import { legacyGinIssueEditRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-edit-entries/src/legacyGinIssueEditRouteEntry.tsx';
import { legacyGinIssueMoveRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-issue-move-entries/src/legacyGinIssueMoveRouteEntry.tsx';
import { legacyGinManageRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-manage-entries/src/legacyGinManageRouteEntry.tsx';
import { legacyGinSubscriptionEditRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-subscription-edit-entries/src/legacyGinSubscriptionEditRouteEntry.tsx';
import { legacyGinSubscriptionsRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-subscriptions-entries/src/legacyGinSubscriptionsRouteEntry.tsx';
import { legacyGinSubtaskActionRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-subtask-action-entries/src/legacyGinSubtaskActionRouteEntry.tsx';
import { legacyGinWatchersIssueRouteEntry } from '@atlassian/jira-router-routes-legacy-gin-watchers-issue-entries/src/legacyGinWatchersIssueRouteEntry.tsx';
import { globalAutomationRouteEntry } from '@atlassian/jira-router-routes-legacy-global-automation-entries/src/globalAutomationRouteEntry.tsx';
import { globalIssueNavigatorBrowseRouteEntry } from '@atlassian/jira-router-routes-legacy-global-issue-navigator-entries/src/globalIssueNavigatorBrowseRouteEntry.tsx';
import { globalIssueNavigatorRedirectRouteEntry } from '@atlassian/jira-router-routes-legacy-global-issue-navigator-entries/src/globalIssueNavigatorRedirectRouteEntry.tsx';
import { globalIssueNavigatorRouteEntry } from '@atlassian/jira-router-routes-legacy-global-issue-navigator-entries/src/globalIssueNavigatorRouteEntry.tsx';
import { globalSettingsAddonsOauthRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-addons-oauth-entries/src/globalSettingsAddonsOauthRouteEntry.tsx';
import { emceeAppRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-apps-entries/src/emceeAppRouteEntry.tsx';
import { manageAppsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-apps-entries/src/manageAppsRouteEntry.tsx';
import { globalSettingsCustomFieldsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-custom-fields-entries/src/globalSettingsCustomFieldsRouteEntry.tsx';
import { globalSettingsFieldLayoutSchemesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-field-layout-schemes-entries/src/globalSettingsFieldLayoutSchemesRouteEntry.tsx';
import { globalSettingsFieldLayoutsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-field-layouts-entries/src/globalSettingsFieldLayoutsRouteEntry.tsx';
import { globalSettingsIssueSubtasksRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issue-subtasks-entries/src/globalSettingsIssueSubtasksRouteEntry.tsx';
import { globalSettingsIssueTimeTrackingRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issue-time-tracking-entries/src/globalSettingsIssueTimeTrackingRouteEntry.tsx';
import { globalSettingsIssueWorkflowRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issue-workflow-entries/src/globalSettingsIssueWorkflowRouteEntry.tsx';
import { globalSettingsIssueWorkflowSchemesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issue-workflow-schemes-entries/src/globalSettingsIssueWorkflowSchemesRouteEntry.tsx';
import { globalSettingsIssuesCustomFieldsAssociateRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-associate-entries/src/globalSettingsIssuesCustomFieldsAssociateRouteEntry.tsx';
import { globalSettingsIssuesCustomFieldsConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-config-entries/src/globalSettingsIssuesCustomFieldsConfigRouteEntry.tsx';
import { globalSettingsIssuesCustomFieldsDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-delete-entries/src/globalSettingsIssuesCustomFieldsDeleteRouteEntry.tsx';
import { globalSettingsIssuesCustomFieldsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-edit-entries/src/globalSettingsIssuesCustomFieldsEditRouteEntry.tsx';
import { globalSettingsIssuesCustomFieldsManageRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-manage-entries/src/globalSettingsIssuesCustomFieldsManageRouteEntry.tsx';
import { globalSettingsIssuesCustomFieldsTranslateRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-custom-fields-translate-entries/src/globalSettingsIssuesCustomFieldsTranslateRouteEntry.tsx';
import { globalSettingsIssuesFieldAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-add-entries/src/globalSettingsIssuesFieldAddRouteEntry.tsx';
import { globalSettingsIssuesFieldConfigureEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-configure-edit-entries/src/globalSettingsIssuesFieldConfigureEditRouteEntry.tsx';
import { globalSettingsIssuesFieldConfigureRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-configure-entries/src/globalSettingsIssuesFieldConfigureRouteEntry.tsx';
import { globalSettingsIssuesFieldCopyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-copy-entries/src/globalSettingsIssuesFieldCopyRouteEntry.tsx';
import { globalSettingsIssuesFieldDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-delete-entries/src/globalSettingsIssuesFieldDeleteRouteEntry.tsx';
import { globalSettingsIssuesFieldEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-edit-entries/src/globalSettingsIssuesFieldEditRouteEntry.tsx';
import { globalSettingsIssuesFieldSchemesAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-add-entries/src/globalSettingsIssuesFieldSchemesAddRouteEntry.tsx';
import { globalSettingsIssuesFieldSchemesConfigureRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-configure-entries/src/globalSettingsIssuesFieldSchemesConfigureRouteEntry.tsx';
import { globalSettingsIssuesFieldSchemesCopyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-copy-entries/src/globalSettingsIssuesFieldSchemesCopyRouteEntry.tsx';
import { globalSettingsIssuesFieldSchemesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-delete-entries/src/globalSettingsIssuesFieldSchemesDeleteRouteEntry.tsx';
import { globalSettingsIssuesFieldSchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-edit-entries/src/globalSettingsIssuesFieldSchemesEditRouteEntry.tsx';
import { globalSettingsIssuesFieldSchemesEditItemRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-edit-item-entries/src/globalSettingsIssuesFieldSchemesEditItemRouteEntry.tsx';
import { globalSettingsIssuesFieldViewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-view-entries/src/globalSettingsIssuesFieldViewRouteEntry.tsx';
import { globalSettingsIssuesFieldlayoutsConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-config-entries/src/globalSettingsIssuesFieldlayoutsConfigRouteEntry.tsx';
import { globalSettingsIssuesFieldlayoutsCopyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-copy-entries/src/globalSettingsIssuesFieldlayoutsCopyRouteEntry.tsx';
import { globalSettingsIssuesFieldlayoutsDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-delete-entries/src/globalSettingsIssuesFieldlayoutsDeleteRouteEntry.tsx';
import { globalSettingsIssuesFieldlayoutsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-edit-entries/src/globalSettingsIssuesFieldlayoutsEditRouteEntry.tsx';
import { globalSettingsIssuesFieldlayoutsSchemesConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-schemes-config-entries/src/globalSettingsIssuesFieldlayoutsSchemesConfigRouteEntry.tsx';
import { globalSettingsIssuesFieldlayoutsSchemesCopyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-schemes-copy-entries/src/globalSettingsIssuesFieldlayoutsSchemesCopyRouteEntry.tsx';
import { globalSettingsIssuesFieldlayoutsSchemesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-schemes-delete-entries/src/globalSettingsIssuesFieldlayoutsSchemesDeleteRouteEntry.tsx';
import { globalSettingsIssuesFieldlayoutsSchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-schemes-edit-entries/src/globalSettingsIssuesFieldlayoutsSchemesEditRouteEntry.tsx';
import { globalSettingsIssuesFieldlayoutsSchemesEntityRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldlayouts-schemes-entity-entries/src/globalSettingsIssuesFieldlayoutsSchemesEntityRouteEntry.tsx';
import { globalSettingsIssuesFieldviewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fieldview-entries/src/globalSettingsIssuesFieldviewRouteEntry.tsx';
import { globalSettingsIssuesGeneralTranslationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-general-translations-entries/src/globalSettingsIssuesGeneralTranslationsRouteEntry.tsx';
import { globalSettingsIssuesLinkTypesActivateRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-link-types-entries/src/globalSettingsIssuesLinkTypesActivateRouteEntry.tsx';
import { globalSettingsIssuesLinkTypesDeactivateRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-link-types-entries/src/globalSettingsIssuesLinkTypesDeactivateRouteEntry.tsx';
import { globalSettingsIssuesLinkTypesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-link-types-entries/src/globalSettingsIssuesLinkTypesRouteEntry.tsx';
import { globalSettingsIssuesLinktypesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-linktypes-delete-entries/src/globalSettingsIssuesLinktypesDeleteRouteEntry.tsx';
import { globalSettingsIssuesLinktypesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-linktypes-edit-entries/src/globalSettingsIssuesLinktypesEditRouteEntry.tsx';
import { globalSettingsIssuesNotificationAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-add-entries/src/globalSettingsIssuesNotificationAddRouteEntry.tsx';
import { globalSettingsIssuesNotificationDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-delete-entries/src/globalSettingsIssuesNotificationDeleteRouteEntry.tsx';
import { globalSettingsIssuesNotificationEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-edit-entries/src/globalSettingsIssuesNotificationEditRouteEntry.tsx';
import { globalSettingsIssuesNotificationSchemeAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-scheme-add-entries/src/globalSettingsIssuesNotificationSchemeAddRouteEntry.tsx';
import { globalSettingsIssuesNotificationSchemeDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-scheme-delete-entries/src/globalSettingsIssuesNotificationSchemeDeleteRouteEntry.tsx';
import { globalSettingsIssuesNotificationSchemeEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-scheme-edit-entries/src/globalSettingsIssuesNotificationSchemeEditRouteEntry.tsx';
import { globalSettingsIssuesNotificationViewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-notification-view-entries/src/globalSettingsIssuesNotificationViewRouteEntry.tsx';
import { globalSettingsIssuesPermissionsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-edit-entries/src/globalSettingsIssuesPermissionsEditRouteEntry.tsx';
import { globalSettingsIssuesPermissionsSchemesAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-schemes-add-entries/src/globalSettingsIssuesPermissionsSchemesAddRouteEntry.tsx';
import { globalSettingsIssuesPermissionsSchemesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-schemes-delete-entries/src/globalSettingsIssuesPermissionsSchemesDeleteRouteEntry.tsx';
import { globalSettingsIssuesPermissionsSchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-schemes-edit-entries/src/globalSettingsIssuesPermissionsSchemesEditRouteEntry.tsx';
import { globalSettingsIssuesPermissionsSchemesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-schemes-entries/src/globalSettingsIssuesPermissionsSchemesRouteEntry.tsx';
import { globalSettingsIssuesPermissionsSchemesProjectRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-permissions-schemes-project-entries/src/globalSettingsIssuesPermissionsSchemesProjectRouteEntry.tsx';
import { globalSettingsIssuesPrioritiesDefaultRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-priorities-default-entries/src/globalSettingsIssuesPrioritiesDefaultRouteEntry.tsx';
import { globalSettingsIssuesPrioritiesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-priorities-delete-entries/src/globalSettingsIssuesPrioritiesDeleteRouteEntry.tsx';
import { globalSettingsIssuesPrioritiesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-priorities-edit-entries/src/globalSettingsIssuesPrioritiesEditRouteEntry.tsx';
import { globalSettingsIssuesPrioritiesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-priorities-entries/src/globalSettingsIssuesPrioritiesRouteEntry.tsx';
import { globalSettingsIssueFieldsManagementRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-fields-entries/src/globalSettingsIssueFieldsManagementRouteEntry.tsx';
import { globalSettingsIssuesPrioritiesTranslationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-priorities-translations-entries/src/globalSettingsIssuesPrioritiesTranslationsRouteEntry.tsx';
import { globalSettingsIssuesResolutionsDefaultRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-resolutions-default-entries/src/globalSettingsIssuesResolutionsDefaultRouteEntry.tsx';
import { globalSettingsIssuesResolutionsDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-resolutions-delete-entries/src/globalSettingsIssuesResolutionsDeleteRouteEntry.tsx';
import { globalSettingsIssuesResolutionsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-resolutions-edit-entries/src/globalSettingsIssuesResolutionsEditRouteEntry.tsx';
import { globalSettingsIssuesResolutionsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-resolutions-entries/src/globalSettingsIssuesResolutionsRouteEntry.tsx';
import { globalSettingsIssuesResolutionsTranslationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-resolutions-translations-entries/src/globalSettingsIssuesResolutionsTranslationsRouteEntry.tsx';
import { globalSettingsIssuesSecurityEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-edit-entries/src/globalSettingsIssuesSecurityEditRouteEntry.tsx';
import { globalSettingsIssuesSecurityLevelAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-level-add-entries/src/globalSettingsIssuesSecurityLevelAddRouteEntry.tsx';
import { globalSettingsIssuesSecurityLevelDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-level-delete-entries/src/globalSettingsIssuesSecurityLevelDeleteRouteEntry.tsx';
import { globalSettingsIssuesSecurityLevelEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-level-edit-entries/src/globalSettingsIssuesSecurityLevelEditRouteEntry.tsx';
import { globalSettingsIssuesSecuritySchemesAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-schemes-add-entries/src/globalSettingsIssuesSecuritySchemesAddRouteEntry.tsx';
import { globalSettingsIssuesSecuritySchemesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-schemes-delete-entries/src/globalSettingsIssuesSecuritySchemesDeleteRouteEntry.tsx';
import { globalSettingsIssuesSecuritySchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-schemes-edit-entries/src/globalSettingsIssuesSecuritySchemesEditRouteEntry.tsx';
import { globalSettingsIssuesSecuritySchemesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-security-schemes-entries/src/globalSettingsIssuesSecuritySchemesRouteEntry.tsx';
import { globalSettingsIssuesStatusesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-statuses-entries/src/globalSettingsIssuesStatusesRouteEntry.tsx';
import { globalSettingsIssuesStatusesTranslationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-statuses-translations-entries/src/globalSettingsIssuesStatusesTranslationsRouteEntry.tsx';
import { globalSettingsIssuesSubtasksDisableRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-subtasks-disable-entries/src/globalSettingsIssuesSubtasksDisableRouteEntry.tsx';
import { globalSettingsIssuesSubtasksEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-subtasks-edit-entries/src/globalSettingsIssuesSubtasksEditRouteEntry.tsx';
import { globalSettingsIssuesSubtasksTranlationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-subtasks-tranlations-entries/src/globalSettingsIssuesSubtasksTranlationsRouteEntry.tsx';
import { globalSettingsIssuesTypesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-delete-entries/src/globalSettingsIssuesTypesDeleteRouteEntry.tsx';
import { globalSettingsIssuesTypesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-edit-entries/src/globalSettingsIssuesTypesEditRouteEntry.tsx';
import { globalSettingsIssuesTypesMigrateRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-migrate-entries/src/globalSettingsIssuesTypesMigrateRouteEntry.tsx';
import { globalSettingsIssuesTypesSchemesAssociateDefaultRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-schemes-associate-default-entries/src/globalSettingsIssuesTypesSchemesAssociateDefaultRouteEntry.tsx';
import { globalSettingsIssuesTypesSchemesAssociateRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-schemes-associate-entries/src/globalSettingsIssuesTypesSchemesAssociateRouteEntry.tsx';
import { globalSettingsIssuesTypesSchemesOptionDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-schemes-option-delete-entries/src/globalSettingsIssuesTypesSchemesOptionDeleteRouteEntry.tsx';
import { globalSettingsIssuesTypesSchemesOptionRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-schemes-option-entries/src/globalSettingsIssuesTypesSchemesOptionRouteEntry.tsx';
import { globalSettingsIssuesTypesScreenSchemesConfigureRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-screen-schemes-configure-entries/src/globalSettingsIssuesTypesScreenSchemesConfigureRouteEntry.tsx';
import { globalSettingsIssuesTypesScreenSchemesCopyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-screen-schemes-copy-entries/src/globalSettingsIssuesTypesScreenSchemesCopyRouteEntry.tsx';
import { globalSettingsIssuesTypesScreenSchemesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-screen-schemes-delete-entries/src/globalSettingsIssuesTypesScreenSchemesDeleteRouteEntry.tsx';
import { globalSettingsIssuesTypesScreenSchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-screen-schemes-edit-entries/src/globalSettingsIssuesTypesScreenSchemesEditRouteEntry.tsx';
import { globalSettingsIssuesTypesScreenSchemesEntityRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-screen-schemes-entity-entries/src/globalSettingsIssuesTypesScreenSchemesEntityRouteEntry.tsx';
import { globalSettingsIssuesTypesTranslationsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-types-translations-entries/src/globalSettingsIssuesTypesTranslationsRouteEntry.tsx';
import { globalSettingsIssuesWorkflowTransitionMetaAttributesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-workflow-transition-entries/src/globalSettingsIssuesWorkflowTransitionMetaAttributesRouteEntry.tsx';
import { globalSettingsIssuesWorkflowTransitionRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-workflow-transition-entries/src/globalSettingsIssuesWorkflowTransitionRouteEntry.tsx';
import { globalSettingsIssuesWorkflowsImportRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-workflows-import-entries/src/globalSettingsIssuesWorkflowsImportRouteEntry.tsx';
import { globalSettingsIssuesWorkflowsSchemesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-issues-workflows-schemes-edit-entries/src/globalSettingsIssuesWorkflowsSchemesEditRouteEntry.tsx';
import { globalSettingsProductsAccessRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-access-entries/src/globalSettingsProductsAccessRouteEntry.tsx';
import { globalSettingsProductsAdvancedRoadmapsHierarchyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-advanced-roadmaps-hierarchy-entries/src/globalSettingsProductsAdvancedRoadmapsHierarchyRouteEntry.tsx';
import { globalSettingsProductsApplinksRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-applinks-entries/src/globalSettingsProductsApplinksRouteEntry.tsx';
import { globalSettingsProductsDvcsBitbucketRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-dvcs-bitbucket-entries/src/globalSettingsProductsDvcsBitbucketRouteEntry.tsx';
import { globalSettingsProductsDvcsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-dvcs-entries/src/globalSettingsProductsDvcsRouteEntry.tsx';
import { globalSettingsProductsJsdStatusRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-jsd-status-entries/src/globalSettingsProductsJsdStatusRouteEntry.tsx';
import { globalSettingsProductsSdconfigRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-sdconfig-entries/src/globalSettingsProductsSdconfigRouteEntry.tsx';
import { globalSettingsProductsSdmailRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-sdmail-entries/src/globalSettingsProductsSdmailRouteEntry.tsx';
import { globalSettingsProductsSoftwareConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-products-software-config-entries/src/globalSettingsProductsSoftwareConfigRouteEntry.tsx';
import { globalSettingsProjectsCategoriesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-projects-categories-delete-entries/src/globalSettingsProjectsCategoriesDeleteRouteEntry.tsx';
import { globalSettingsProjectsCategoriesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-projects-categories-edit-entries/src/globalSettingsProjectsCategoriesEditRouteEntry.tsx';
import { globalSettingsProjectsCategoriesViewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-projects-categories-view-entries/src/globalSettingsProjectsCategoriesViewRouteEntry.tsx';
import { globalSettingsSystemApplicationAdvancedRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-application-advanced-entries/src/globalSettingsSystemApplicationAdvancedRouteEntry.tsx';
import { globalSettingsSystemApplicationEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-application-edit-entries/src/globalSettingsSystemApplicationEditRouteEntry.tsx';
import { globalSettingsSystemApplicationViewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-application-view-entries/src/globalSettingsSystemApplicationViewRouteEntry.tsx';
import { globalSettingsSystemAttachmentsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-attachments-entries/src/globalSettingsSystemAttachmentsRouteEntry.tsx';
import { globalSettingsSystemAuditingRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-auditing-entries/src/globalSettingsSystemAuditingRouteEntry.tsx';
import { globalSettingsSystemCollectorsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-collectors-entries/src/globalSettingsSystemCollectorsRouteEntry.tsx';
import { globalSettingsSystemDefaultSettingsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-default-settings-entries/src/globalSettingsSystemDefaultSettingsRouteEntry.tsx';
import { globalSettingsSystemEventsAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-events-entries/src/globalSettingsSystemEventsAddRouteEntry.tsx';
import { globalSettingsSystemEventsDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-events-entries/src/globalSettingsSystemEventsDeleteRouteEntry.tsx';
import { globalSettingsSystemEventsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-events-entries/src/globalSettingsSystemEventsEditRouteEntry.tsx';
import { globalSettingsSystemEventsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-events-entries/src/globalSettingsSystemEventsRouteEntry.tsx';
import { globalSettingsSystemExportRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-export-entries/src/globalSettingsSystemExportRouteEntry.tsx';
import { globalSettingsSystemGlobalPermissionsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-global-permissions-entries/src/globalSettingsSystemGlobalPermissionsRouteEntry.tsx';
import { globalSettingsSystemHelperNotificationRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-helper-notification-entries/src/globalSettingsSystemHelperNotificationRouteEntry.tsx';
import { globalSettingsSystemHelperPermissionRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-helper-permission-entries/src/globalSettingsSystemHelperPermissionRouteEntry.tsx';
import { globalSettingsSystemImportBulkRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-bulk-entries/src/globalSettingsSystemImportBulkRouteEntry.tsx';
import { globalSettingsSystemImportCloudRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-cloud-entries/src/globalSettingsSystemImportCloudRouteEntry.tsx';
import { globalSettingsSystemImportCsvRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-csv-entries/src/globalSettingsSystemImportCsvRouteEntry.tsx';
import { globalSettingsSystemImportExternalRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-external-entries/src/globalSettingsSystemImportExternalRouteEntry.tsx';
import { globalSettingsSystemImportExternalPagesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-external-pages-entries/src/globalSettingsSystemImportExternalPagesRouteEntry.tsx';
import { globalSettingsSystemImportJsonRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-json-entries/src/globalSettingsSystemImportJsonRouteEntry.tsx';
import { globalSettingsSystemImportTrelloRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-import-trello-entries/src/globalSettingsSystemImportTrelloRouteEntry.tsx';
import { globalSettingsSystemLexorankRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-lexorank-entries/src/globalSettingsSystemLexorankRouteEntry.tsx';
import { globalSettingsSystemLookRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-look-entries/src/globalSettingsSystemLookRouteEntry.tsx';
import { globalSettingsSystemMailAddRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-add-entries/src/globalSettingsSystemMailAddRouteEntry.tsx';
import { globalSettingsSystemMailDeleteMailHandlerRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-incoming-entries/src/globalSettingsSystemMailDeleteMailHandlerRouteEntry.tsx';
import { globalSettingsSystemMailIncomingRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-incoming-entries/src/globalSettingsSystemMailIncomingRouteEntry.tsx';
import { globalSettingsSystemMailOutgoingRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-outgoing-entries/src/globalSettingsSystemMailOutgoingRouteEntry.tsx';
import { globalSettingsSystemMailSendRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-send-entries/src/globalSettingsSystemMailSendRouteEntry.tsx';
import { globalSettingsSystemMailSettingsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-settings-entries/src/globalSettingsSystemMailSettingsRouteEntry.tsx';
import { globalSettingsSystemMailVerifyRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-mail-verify-entries/src/globalSettingsSystemMailVerifyRouteEntry.tsx';
import { globalSettingsSystemPermissionsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-permissions-entries/src/globalSettingsSystemPermissionsRouteEntry.tsx';
import { globalSettingsSystemProjectAssignSecurityRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-project-assign-security-entries/src/globalSettingsSystemProjectAssignSecurityRouteEntry.tsx';
import { globalSettingsSystemProjectDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-project-delete-entries/src/globalSettingsSystemProjectDeleteRouteEntry.tsx';
import { globalSettingsSystemProjectSelectRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-project-select-entries/src/globalSettingsSystemProjectSelectRouteEntry.tsx';
import { globalSettingsSystemRolesActorsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-actors-entries/src/globalSettingsSystemRolesActorsRouteEntry.tsx';
import { globalSettingsSystemRolesActorsGroupsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-actors-groups-entries/src/globalSettingsSystemRolesActorsGroupsRouteEntry.tsx';
import { globalSettingsSystemRolesActorsUsersRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-actors-users-entries/src/globalSettingsSystemRolesActorsUsersRouteEntry.tsx';
import { globalSettingsSystemRolesDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-delete-entries/src/globalSettingsSystemRolesDeleteRouteEntry.tsx';
import { globalSettingsSystemRolesEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-edit-entries/src/globalSettingsSystemRolesEditRouteEntry.tsx';
import { globalSettingsSystemRolesUsageRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-usage-entries/src/globalSettingsSystemRolesUsageRouteEntry.tsx';
import { globalSettingsSystemRolesViewRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-roles-view-entries/src/globalSettingsSystemRolesViewRouteEntry.tsx';
import { globalSettingsSystemServicesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-services-entries/src/globalSettingsSystemServicesRouteEntry.tsx';
import { globalSettingsSystemSharedDashboardsRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-shared-dashboards-entries/src/globalSettingsSystemSharedDashboardsRouteEntry.tsx';
import { globalSettingsSystemSharedFiltersRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-shared-filters-entries/src/globalSettingsSystemSharedFiltersRouteEntry.tsx';
import { globalSettingsSystemUserInterfaceDashboardEditRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-user-interface-dashboard-edit-entries/src/globalSettingsSystemUserInterfaceDashboardEditRouteEntry.tsx';
import { globalSettingsSystemUserInterfacePreferencesRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-user-interface-preferences-entries/src/globalSettingsSystemUserInterfacePreferencesRouteEntry.tsx';
import { globalSettingsSystemWebhooksRouteEntry } from '@atlassian/jira-router-routes-legacy-global-settings-system-webhooks-entries/src/globalSettingsSystemWebhooksRouteEntry.tsx';
import { legacyIssueRouteEntry } from '@atlassian/jira-router-routes-legacy-issue-entries/src/legacyIssueRouteEntry.tsx';
import { legacyJsdCustomersRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-customers-entries/src/legacyJsdCustomersRouteEntry.tsx';
import { legacyJsdGettingStartedRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-getting-started-entries/src/legacyJsdGettingStartedRouteEntry.tsx';
import { legacyJsdJiraAutomationRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-jira-automation-entries/src/legacyJsdJiraAutomationRouteEntry.tsx';
import { legacyJsdKnowledgeRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-knowledge-entries/src/legacyJsdKnowledgeRouteEntry.tsx';
import { legacyJsdQueuesRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-queues-entries/src/legacyJsdQueuesRouteEntry.tsx';
import { legacyJsdQueuesManageRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-queues-manage-entries/src/legacyJsdQueuesManageRouteEntry.tsx';
import { legacyJsdReportsCustomRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-reports-custom-entries/src/legacyJsdReportsCustomRouteEntry.tsx';
import { legacyJsdReportsRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-reports-entries/src/legacyJsdReportsRouteEntry.tsx';
import { legacyJsdSettingsFallbackRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-settings-fallback-entries/src/legacyJsdSettingsFallbackRouteEntry.tsx';
import { legacyJsdSettingsLanguageRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-settings-language-entries/src/legacyJsdSettingsLanguageRouteEntry.tsx';
import { legacyJsdSettingsLanguagePagesRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-settings-language-pages-entries/src/legacyJsdSettingsLanguagePagesRouteEntry.tsx';
import { legacyJsdWidgetsRouteEntry } from '@atlassian/jira-router-routes-legacy-jsd-widgets-entries/src/legacyJsdWidgetsRouteEntry.tsx';
import { legacyPersonalSettingsAccessTokensRouteEntry } from '@atlassian/jira-router-routes-legacy-personal-settings-access-tokens-entries/src/legacyPersonalSettingsAccessTokensRouteEntry.tsx';
import { legacyPinRouteEntry } from '@atlassian/jira-router-routes-legacy-pin-entries/src/legacyPinRouteEntry.tsx';
import { legacyPortfolioPagesRouteEntry } from '@atlassian/jira-router-routes-legacy-portfolio-pages-entries/src/legacyPortfolioPagesRouteEntry.tsx';
import { legacyPortfolioTeamRouteEntry } from '@atlassian/jira-router-routes-legacy-portfolio-team-entries/src/legacyPortfolioTeamRouteEntry.tsx';
import { legacyProjectReleasesBambooRouteEntry } from '@atlassian/jira-router-routes-legacy-project-releases-bamboo-entries/src/legacyProjectReleasesBambooRouteEntry.tsx';
import { legacyProjectReleasesRouteEntry } from '@atlassian/jira-router-routes-legacy-project-releases-entries/src/legacyProjectReleasesRouteEntry.tsx';
import { legacyProjectReleasesNoteConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-project-releases-note-config-entries/src/legacyProjectReleasesNoteConfigRouteEntry.tsx';
import { legacyProjectReleasesNoteRouteEntry } from '@atlassian/jira-router-routes-legacy-project-releases-note-entries/src/legacyProjectReleasesNoteRouteEntry.tsx';
import { legacyProjectSettingsCollectorsAddRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-collectors-add-entries/src/legacyProjectSettingsCollectorsAddRouteEntry.tsx';
import { legacyProjectSettingsCollectorsRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-collectors-entries/src/legacyProjectSettingsCollectorsRouteEntry.tsx';
import { legacyProjectSettingsCollectorsHelpRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-collectors-help-entries/src/legacyProjectSettingsCollectorsHelpRouteEntry.tsx';
import { legacyProjectSettingsCollectorsViewRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-collectors-view-entries/src/legacyProjectSettingsCollectorsViewRouteEntry.tsx';
import { legacyProjectSettingsConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-config-entries/src/legacyProjectSettingsConfigRouteEntry.tsx';
import { legacyProjectSettingsDeleteRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-delete-entries/src/legacyProjectSettingsDeleteRouteEntry.tsx';
import { legacyProjectSettingsDevRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-dev-entries/src/legacyProjectSettingsDevRouteEntry.tsx';
import { legacyProjectSettingsEditRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-edit-entries/src/legacyProjectSettingsEditRouteEntry.tsx';
import { legacyProjectSettingsFieldsAssociateRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-fields-entries/src/legacyProjectSettingsFieldsAssociateRouteEntry.tsx';
import { legacyProjectSettingsFieldsRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-fields-entries/src/legacyProjectSettingsFieldsRouteEntry.tsx';
import { legacyProjectSettingsIssueLayoutRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-issue-layout-entries/src/legacyProjectSettingsIssueLayoutRouteEntry.tsx';
import { legacyProjectSettingsIssueSecurityAssociateRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-issue-security-entries/src/legacyProjectSettingsIssueSecurityAssociateRouteEntry.tsx';
import { legacyProjectSettingsIssueSecurityRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-issue-security-entries/src/legacyProjectSettingsIssueSecurityRouteEntry.tsx';
import { legacyProjectSettingsIssueTypesAssociateRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-issue-types-entries/src/legacyProjectSettingsIssueTypesAssociateRouteEntry.tsx';
import { legacyProjectSettingsIssueTypesRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-issue-types-entries/src/legacyProjectSettingsIssueTypesRouteEntry.tsx';
import { legacyProjectSettingsNotificationsAssociateRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-notifications-entries/src/legacyProjectSettingsNotificationsAssociateRouteEntry.tsx';
import { legacyProjectSettingsNotificationsRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-notifications-entries/src/legacyProjectSettingsNotificationsRouteEntry.tsx';
import { legacyProjectSettingsOpsgenieRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-opsgenie-entries/src/legacyProjectSettingsOpsgenieRouteEntry.tsx';
import { legacyProjectSettingsPeopleRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-people-entries/src/legacyProjectSettingsPeopleRouteEntry.tsx';
import { legacyProjectSettingsPermissionsRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-permissions-entries/src/legacyProjectSettingsPermissionsRouteEntry.tsx';
import { legacyProjectSettingsScreensAssociateRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-screens-entries/src/legacyProjectSettingsScreensAssociateRouteEntry.tsx';
import { legacyProjectSettingsScreensRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-screens-entries/src/legacyProjectSettingsScreensRouteEntry.tsx';
import { legacyProjectSettingsSummaryRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-summary-entries/src/legacyProjectSettingsSummaryRouteEntry.tsx';
import { legacyProjectSettingsWorkflowsAssociateRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-workflows-entries/src/legacyProjectSettingsWorkflowsAssociateRouteEntry.tsx';
import { legacyProjectSettingsWorkflowsRouteEntry } from '@atlassian/jira-router-routes-legacy-project-settings-workflows-entries/src/legacyProjectSettingsWorkflowsRouteEntry.tsx';
import { legacyReportsAverageAgeRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-average-age-entries/src/legacyReportsAverageAgeRouteEntry.tsx';
import { legacyReportsConfigRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-config-entries/src/legacyReportsConfigRouteEntry.tsx';
import { legacyReportsCreatedVsResolvedRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-created-vs-resolved-entries/src/legacyReportsCreatedVsResolvedRouteEntry.tsx';
import { legacyReportsDeveloperWorkloadRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-developer-workload-entries/src/legacyReportsDeveloperWorkloadRouteEntry.tsx';
import { legacyReportsPieRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-pie-entries/src/legacyReportsPieRouteEntry.tsx';
import { legacyReportsRecentlyCreatedRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-recently-created-entries/src/legacyReportsRecentlyCreatedRouteEntry.tsx';
import { legacyReportsResolutionTimeRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-resolution-time-entries/src/legacyReportsResolutionTimeRouteEntry.tsx';
import { legacyReportsSingleLevelGroupByRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-single-level-group-by-entries/src/legacyReportsSingleLevelGroupByRouteEntry.tsx';
import { legacyReportsTimeSinceRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-time-since-entries/src/legacyReportsTimeSinceRouteEntry.tsx';
import { legacyReportsTimeTrackingReportRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-time-tracking-report-entries/src/legacyReportsTimeTrackingReportRouteEntry.tsx';
import { legacyReportsVersionWorkloadRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-version-workload-entries/src/legacyReportsVersionWorkloadRouteEntry.tsx';
import { legacyReportsWorkloadPieRouteEntry } from '@atlassian/jira-router-routes-legacy-reports-workload-pie-entries/src/legacyReportsWorkloadPieRouteEntry.tsx';
import { workflowDesignerRouteEntry } from '@atlassian/jira-router-routes-legacy-workflow-designer-entries/src/workflowDesignerRouteEntry.tsx';
import type { Route } from '@atlassian/react-resource-router';
import applyLazyOpts from '@atlassian/react-resource-router/src/common/utils/apply-lazy-opts';

type PartialRoute = Omit<Route, 'component' | 'forPaint' | 'preloadOptions'> & {
	component?: Route['component'];
	forPaint?: Route['forPaint'];
	preloadOptions?: Route['preloadOptions'];
};

/*
    These routes are only used when the SPA route didn't hit in backend,
    and it rendered navigation-skeleton bundle not jira-spa bundle. This means these route are not used in SPA.
    Please delete these routes if your legacy page is not used anymore.
 */
export const getRawRoutes = (): PartialRoute[] => [
	legacyJsdGettingStartedRouteEntry,
	legacyJsdCustomersRouteEntry,
	legacyJsdKnowledgeRouteEntry,
	legacyJsdQueuesManageRouteEntry,
	legacyJsdQueuesRouteEntry,
	legacyJsdReportsCustomRouteEntry,
	legacyJsdReportsRouteEntry,
	legacyJsdSettingsLanguageRouteEntry,
	legacyJsdSettingsLanguagePagesRouteEntry,
	legacyJsdJiraAutomationRouteEntry,
	legacyJsdWidgetsRouteEntry,
	legacyJsdSettingsFallbackRouteEntry,
	legacyCustomReportRouteEntry,
	legacyBacklogRouteEntry,
	legacyBoardRapidViewRouteEntry,
	legacyBoardRouteEntry,
	legacyAllReportsRouteEntry,
	globalIssueNavigatorRouteEntry,
	globalIssueNavigatorBrowseRouteEntry,
	globalIssueNavigatorRedirectRouteEntry,
	legacyIssueRouteEntry,
	legacyPinRouteEntry,
	legacyProjectReleasesRouteEntry,
	legacyProjectReleasesNoteRouteEntry,
	legacyProjectReleasesNoteConfigRouteEntry,
	legacyProjectReleasesBambooRouteEntry,
	legacyReportsAverageAgeRouteEntry,
	legacyReportsCreatedVsResolvedRouteEntry,
	legacyReportsPieRouteEntry,
	legacyReportsRecentlyCreatedRouteEntry,
	legacyReportsResolutionTimeRouteEntry,
	legacyReportsSingleLevelGroupByRouteEntry,
	legacyReportsTimeSinceRouteEntry,
	legacyReportsTimeTrackingReportRouteEntry,
	legacyReportsDeveloperWorkloadRouteEntry,
	legacyReportsVersionWorkloadRouteEntry,
	legacyReportsWorkloadPieRouteEntry,
	legacyReportsConfigRouteEntry,
	legacyConnectProjectRouteEntry,
	legacyProjectSettingsPeopleRouteEntry,
	legacyProjectSettingsSummaryRouteEntry,
	legacyProjectSettingsIssueTypesRouteEntry,
	legacyProjectSettingsIssueTypesAssociateRouteEntry,
	legacyProjectSettingsIssueLayoutRouteEntry,
	legacyProjectSettingsWorkflowsRouteEntry,
	legacyProjectSettingsWorkflowsAssociateRouteEntry,
	legacyProjectSettingsScreensRouteEntry,
	legacyProjectSettingsScreensAssociateRouteEntry,
	legacyProjectSettingsFieldsRouteEntry,
	legacyProjectSettingsFieldsAssociateRouteEntry,
	legacyProjectSettingsOpsgenieRouteEntry,
	legacyProjectSettingsPermissionsRouteEntry,
	legacyProjectSettingsIssueSecurityRouteEntry,
	legacyProjectSettingsIssueSecurityAssociateRouteEntry,
	legacyProjectSettingsNotificationsRouteEntry,
	legacyProjectSettingsNotificationsAssociateRouteEntry,
	legacyProjectSettingsConfigRouteEntry,
	legacyProjectSettingsEditRouteEntry,
	legacyProjectSettingsDeleteRouteEntry,
	legacyProjectSettingsCollectorsRouteEntry,
	legacyProjectSettingsCollectorsAddRouteEntry,
	legacyProjectSettingsCollectorsHelpRouteEntry,
	legacyProjectSettingsCollectorsViewRouteEntry,
	legacyProjectSettingsDevRouteEntry,
	legacyDashboardsConfigureRouteEntry,
	dashboardsDirectoryRouteEntry,
	legacyDashboardsAddRouteEntry,
	legacyDashboardsEditRouteEntry,
	legacyDashboardsDeleteRouteEntry,
	legacyDashboardsRestoreRouteEntry,
	legacyGinManageRouteEntry,
	legacyGinBulkeditRouteEntry,
	legacyGinBulkRouteEntry,
	legacyGinIssueMoveRouteEntry,
	legacyGinSubtaskActionRouteEntry,
	legacyGinIssueCloneRouteEntry,
	legacyGinIssueAssignRouteEntry,
	legacyGinIssueCreateRouteEntry,
	legacyGinIssueConvertRouteEntry,
	legacyGinIssueEditRouteEntry,
	legacyGinWatchersIssueRouteEntry,
	legacyGinActionRouteEntry,
	legacyGinSubscriptionsRouteEntry,
	legacyGinSubscriptionEditRouteEntry,
	legacyPortfolioPagesRouteEntry,
	legacyPortfolioTeamRouteEntry,
	globalSettingsSystemApplicationViewRouteEntry,
	globalSettingsSystemApplicationAdvancedRouteEntry,
	globalSettingsSystemApplicationEditRouteEntry,
	globalSettingsSystemAuditingRouteEntry,
	globalSettingsSystemRolesViewRouteEntry,
	globalSettingsSystemRolesUsageRouteEntry,
	globalSettingsSystemRolesActorsRouteEntry,
	globalSettingsSystemRolesActorsGroupsRouteEntry,
	globalSettingsSystemRolesActorsUsersRouteEntry,
	globalSettingsSystemRolesEditRouteEntry,
	globalSettingsSystemRolesDeleteRouteEntry,
	globalSettingsSystemProjectDeleteRouteEntry,
	globalSettingsSystemProjectAssignSecurityRouteEntry,
	globalSettingsSystemPermissionsRouteEntry,
	globalSettingsSystemCollectorsRouteEntry,
	globalSettingsSystemDefaultSettingsRouteEntry,
	globalSettingsSystemLookRouteEntry,
	globalSettingsSystemExportRouteEntry,
	globalSettingsSystemGlobalPermissionsRouteEntry,
	globalSettingsSystemImportExternalRouteEntry,
	globalSettingsSystemImportExternalPagesRouteEntry,
	globalSettingsSystemImportCsvRouteEntry,
	globalSettingsSystemImportJsonRouteEntry,
	globalSettingsSystemImportTrelloRouteEntry,
	globalSettingsSystemImportCloudRouteEntry,
	globalSettingsSystemImportBulkRouteEntry,
	globalAutomationRouteEntry,
	globalSettingsSystemMailSettingsRouteEntry,
	globalSettingsSystemMailOutgoingRouteEntry,
	globalSettingsSystemMailDeleteMailHandlerRouteEntry,
	globalSettingsSystemMailIncomingRouteEntry,
	globalSettingsSystemMailAddRouteEntry,
	globalSettingsSystemMailVerifyRouteEntry,
	globalSettingsSystemMailSendRouteEntry,
	globalSettingsSystemHelperPermissionRouteEntry,
	globalSettingsSystemHelperNotificationRouteEntry,
	globalSettingsSystemSharedFiltersRouteEntry,
	globalSettingsSystemSharedDashboardsRouteEntry,
	globalSettingsSystemAttachmentsRouteEntry,
	globalSettingsSystemEventsRouteEntry,
	globalSettingsSystemEventsAddRouteEntry,
	globalSettingsSystemEventsDeleteRouteEntry,
	globalSettingsSystemEventsEditRouteEntry,
	globalSettingsSystemUserInterfaceDashboardEditRouteEntry,
	globalSettingsSystemUserInterfacePreferencesRouteEntry,
	globalSettingsSystemWebhooksRouteEntry,
	globalSettingsSystemServicesRouteEntry,
	globalSettingsSystemLexorankRouteEntry,
	globalSettingsProductsAccessRouteEntry,
	globalSettingsProductsSoftwareConfigRouteEntry,
	globalSettingsProductsAdvancedRoadmapsHierarchyRouteEntry,
	globalSettingsProductsSdconfigRouteEntry,
	globalSettingsProductsSdmailRouteEntry,
	globalSettingsProductsApplinksRouteEntry,
	globalSettingsProductsDvcsRouteEntry,
	globalSettingsProductsDvcsBitbucketRouteEntry,
	globalSettingsProductsJsdStatusRouteEntry,
	globalSettingsProjectsCategoriesViewRouteEntry,
	globalSettingsProjectsCategoriesEditRouteEntry,
	globalSettingsProjectsCategoriesDeleteRouteEntry,
	globalSettingsIssuesTypesEditRouteEntry,
	globalSettingsIssuesTypesDeleteRouteEntry,
	globalSettingsIssuesTypesMigrateRouteEntry,
	globalSettingsIssuesTypesTranslationsRouteEntry,
	globalSettingsIssuesTypesSchemesOptionRouteEntry,
	globalSettingsIssuesTypesSchemesAssociateDefaultRouteEntry,
	globalSettingsIssuesTypesSchemesAssociateRouteEntry,
	globalSettingsIssuesTypesSchemesOptionDeleteRouteEntry,
	globalSettingsIssueSubtasksRouteEntry,
	globalSettingsIssuesSubtasksDisableRouteEntry,
	globalSettingsIssuesSubtasksEditRouteEntry,
	globalSettingsIssuesSubtasksTranlationsRouteEntry,
	workflowDesignerRouteEntry,
	globalSettingsIssueWorkflowRouteEntry,
	globalSettingsIssuesWorkflowTransitionRouteEntry,
	globalSettingsIssuesWorkflowTransitionMetaAttributesRouteEntry,
	globalSettingsIssuesWorkflowsImportRouteEntry,
	globalSettingsIssueWorkflowSchemesRouteEntry,
	globalSettingsIssuesWorkflowsSchemesEditRouteEntry,
	globalSettingsIssuesFieldViewRouteEntry,
	globalSettingsIssuesFieldAddRouteEntry,
	globalSettingsIssuesFieldConfigureRouteEntry,
	globalSettingsIssuesFieldConfigureEditRouteEntry,
	globalSettingsIssuesFieldEditRouteEntry,
	globalSettingsIssuesFieldCopyRouteEntry,
	globalSettingsIssuesFieldDeleteRouteEntry,
	globalSettingsIssuesFieldSchemesAddRouteEntry,
	globalSettingsIssuesFieldSchemesConfigureRouteEntry,
	globalSettingsIssuesFieldSchemesEditRouteEntry,
	globalSettingsIssuesFieldSchemesEditItemRouteEntry,
	globalSettingsIssuesFieldSchemesCopyRouteEntry,
	globalSettingsIssuesFieldSchemesDeleteRouteEntry,
	globalSettingsIssuesTypesScreenSchemesConfigureRouteEntry,
	globalSettingsIssuesTypesScreenSchemesEntityRouteEntry,
	globalSettingsIssuesTypesScreenSchemesEditRouteEntry,
	globalSettingsIssuesTypesScreenSchemesCopyRouteEntry,
	globalSettingsIssuesTypesScreenSchemesDeleteRouteEntry,
	globalSettingsCustomFieldsRouteEntry,
	globalSettingsIssuesCustomFieldsConfigRouteEntry,
	globalSettingsIssuesCustomFieldsEditRouteEntry,
	globalSettingsIssuesCustomFieldsTranslateRouteEntry,
	globalSettingsIssuesCustomFieldsDeleteRouteEntry,
	globalSettingsIssuesCustomFieldsManageRouteEntry,
	globalSettingsIssuesCustomFieldsAssociateRouteEntry,
	globalSettingsIssuesExplicitFieldAssociationRouteEntry,
	globalSettingsFieldLayoutsRouteEntry,
	globalSettingsIssuesFieldlayoutsConfigRouteEntry,
	globalSettingsIssuesFieldviewRouteEntry,
	globalSettingsIssuesFieldlayoutsCopyRouteEntry,
	globalSettingsIssuesFieldlayoutsEditRouteEntry,
	globalSettingsIssuesFieldlayoutsDeleteRouteEntry,
	globalSettingsFieldLayoutSchemesRouteEntry,
	globalSettingsIssuesFieldlayoutsSchemesConfigRouteEntry,
	globalSettingsIssuesFieldlayoutsSchemesEntityRouteEntry,
	globalSettingsIssuesFieldlayoutsSchemesCopyRouteEntry,
	globalSettingsIssuesFieldlayoutsSchemesEditRouteEntry,
	globalSettingsIssuesFieldlayoutsSchemesDeleteRouteEntry,
	globalSettingsIssueTimeTrackingRouteEntry,
	globalSettingsIssuesLinkTypesRouteEntry,
	globalSettingsIssuesLinkTypesActivateRouteEntry,
	globalSettingsIssuesLinkTypesDeactivateRouteEntry,
	globalSettingsIssuesLinktypesEditRouteEntry,
	globalSettingsIssuesLinktypesDeleteRouteEntry,
	globalSettingsIssuesStatusesRouteEntry,
	globalSettingsIssuesStatusesTranslationsRouteEntry,
	globalSettingsIssuesResolutionsRouteEntry,
	globalSettingsIssuesResolutionsTranslationsRouteEntry,
	globalSettingsIssuesResolutionsDefaultRouteEntry,
	globalSettingsIssuesResolutionsEditRouteEntry,
	globalSettingsIssuesResolutionsDeleteRouteEntry,
	globalSettingsIssuesPrioritiesRouteEntry,
	globalSettingsIssueFieldsManagementRouteEntry,
	globalSettingsIssuesPrioritiesTranslationsRouteEntry,
	globalSettingsIssuesGeneralTranslationsRouteEntry,
	globalSettingsIssuesPrioritiesEditRouteEntry,
	globalSettingsIssuesPrioritiesDeleteRouteEntry,
	globalSettingsIssuesPrioritiesDefaultRouteEntry,
	globalSettingsIssuesSecuritySchemesRouteEntry,
	globalSettingsIssuesSecurityEditRouteEntry,
	globalSettingsIssuesSecurityLevelAddRouteEntry,
	globalSettingsIssuesSecurityLevelEditRouteEntry,
	globalSettingsIssuesSecurityLevelDeleteRouteEntry,
	globalSettingsIssuesSecuritySchemesAddRouteEntry,
	globalSettingsIssuesSecuritySchemesEditRouteEntry,
	globalSettingsIssuesSecuritySchemesDeleteRouteEntry,
	globalSettingsIssuesNotificationViewRouteEntry,
	globalSettingsIssuesNotificationSchemeAddRouteEntry,
	globalSettingsIssuesNotificationSchemeEditRouteEntry,
	globalSettingsIssuesNotificationSchemeDeleteRouteEntry,
	globalSettingsIssuesNotificationAddRouteEntry,
	globalSettingsIssuesNotificationEditRouteEntry,
	globalSettingsIssuesNotificationDeleteRouteEntry,
	globalSettingsIssuesPermissionsSchemesRouteEntry,
	globalSettingsIssuesPermissionsSchemesAddRouteEntry,
	globalSettingsIssuesPermissionsEditRouteEntry,
	globalSettingsIssuesPermissionsSchemesEditRouteEntry,
	globalSettingsIssuesPermissionsSchemesProjectRouteEntry,
	globalSettingsIssuesPermissionsSchemesDeleteRouteEntry,
	addonsEmceePublicRouteEntry,
	emceeAppRouteEntry,
	manageAppsRouteEntry,
	globalSettingsAddonsOauthRouteEntry,
	globalSettingsSystemProjectSelectRouteEntry, // this route uses regex, so it should be defined last
	legacyPersonalSettingsAccessTokensRouteEntry,
	legacyConnectGeneralRouteEntry,
	legacyFallbackRouteEntry,
];

export const getLegacyRoutes = (): Route[] =>
	getRawRoutes()
		.map(applyLazyOpts)
		.map((route: Route) => ({
			...route,
			group: 'legacy',
			component: route.component || LegacyPage,
			resources: [
				...new Set([...getNavigationResources(), ...(route.resources ? route.resources : [])]),
			],
		}));
