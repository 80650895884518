import { legacyPageEntrypoint } from '@atlassian/jira-router-routes-legacy-page-entrypoint/src/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsSystem from '@atlassian/jira-navigation-apps-sidebar-global-settings-system/src/async.tsx';
import { createLegacyEntry } from '@atlassian/jira-route-entry/src/createLegacyEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { globalAutomationRoute } from '@atlassian/jira-router-routes-legacy-global-automation-routes/src/globalAutomationRoute.tsx';

const pageEntryPoint = createPageEntryPoint({
	main: legacyPageEntrypoint,
});

export const globalAutomationRouteEntry = createLegacyEntry(globalAutomationRoute, () => ({
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_SYSTEM_SETTINGS,
		sidebar: AsyncSidebarGlobalSettingsSystem,
	},

	resources: [navigationSidebarGlobalResource],
	entryPoint: () => pageEntryPoint,
}));
