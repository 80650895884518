import { legacyPageEntrypoint } from '@atlassian/jira-router-routes-legacy-page-entrypoint/src/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProducts from '@atlassian/jira-navigation-apps-sidebar-global-settings-products/src/async.tsx';
import { createLegacyEntry } from '@atlassian/jira-route-entry/src/createLegacyEntry.tsx';
import { getNavigationSidebarProductsSettingResource } from '@atlassian/jira-router-resources-navigation/src/index.tsx';
import { globalSettingsProductsSdmailRoute } from '@atlassian/jira-router-routes-legacy-global-settings-products-sdmail-routes/src/globalSettingsProductsSdmailRoute.tsx';

const pageEntryPoint = createPageEntryPoint({
	main: legacyPageEntrypoint,
});

export const globalSettingsProductsSdmailRouteEntry = createLegacyEntry(
	globalSettingsProductsSdmailRoute,
	() => ({
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PRODUCTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProducts,
		},

		resources: getNavigationSidebarProductsSettingResource(),
		entryPoint: () => pageEntryPoint,
	}),
);
