import { useCallback } from 'react';
import noop from 'lodash/noop';
import { useFlagsService } from '@atlassian/jira-flags';
import messages from './messages.tsx';

const NEW_EDITOR_SUCCESS_FLAG = 'new-editor-success-flag';

const learnMoreLinkNewEditor =
	'https://support.atlassian.com/jira-service-management-cloud/docs/using-the-new-workflow-editor/';
const learnMoreLinkOldEditor =
	'https://support.atlassian.com/jira-service-management-cloud/docs/changing-your-default-workflow-editor/';

export const useChangePreferenceFlag = () => {
	const { showFlag, dismissFlag } = useFlagsService();
	const onClickLearnMoreOldEditor = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(learnMoreLinkOldEditor, '_blank');
	};

	const onClickLearnMoreNewEditor = () => {
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.open(learnMoreLinkNewEditor, '_blank');
	};

	const showNewEditorSuccessFlag = useCallback(
		() =>
			showFlag({
				id: NEW_EDITOR_SUCCESS_FLAG,
				testId: 'workflow-editor-preference-settings.ui.success-flag',
				type: 'success',
				title: messages.changeToNewEditorTitle,
				description: messages.changeToNewEditorDescription,
				messageId:
					'workflow-editor-preference-settings.controllers.change-preference-flag.show-flag.success.change-to-new-editor',
				messageType: 'transactional',
				actions: [
					{
						content: messages.learnMore,
						onClick: onClickLearnMoreNewEditor,
					},
					{
						content: messages.ok,
						onClick: () => dismissFlag(NEW_EDITOR_SUCCESS_FLAG),
					},
				],
			}),
		[dismissFlag, showFlag],
	);

	const showOldEditorSuccessFlag = useCallback(
		(openDefaultEditorDialog: () => void = noop) =>
			showFlag({
				type: 'success',
				title: messages.changeToOldEditorTitle,
				description: messages.changeToOldEditorDescription,
				messageId:
					'workflow-editor-preference-settings.controllers.change-preference-flag.show-flag.success.change-to-old-editor',
				messageType: 'transactional',
				actions: [
					{
						content: messages.learnMore,
						onClick: onClickLearnMoreOldEditor,
					},
					{
						content: messages.switchToNewEditor,
						onClick: openDefaultEditorDialog,
					},
				],
			}),
		[showFlag],
	);

	const showErrorFlag = useCallback(
		() =>
			showFlag({
				type: 'error',
				title: messages.updatePreferenceErrorFlagTitle,
				description: messages.updatePreferenceErrorFlagDescription,
				messageId:
					'workflow-editor-preference-settings.controllers.change-preference-flag.show-flag.error.update-preference-error',
				messageType: 'transactional',
			}),
		[showFlag],
	);

	return { showNewEditorSuccessFlag, showOldEditorSuccessFlag, showErrorFlag };
};
