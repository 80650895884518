import React from 'react';
import AsyncOpenHelpPanelLinkThroughEventListener from '@atlassian/jira-help-panel-button/src/async.tsx';
import AsyncLegacyTeamProfileCard from '@atlassian/jira-profilecard-next/src/ui/team-profilecard-legacy/async.tsx';
import { LegacyPage } from '@atlassian/jira-router-routes-common/src/ui/legacy-page/main.tsx';
import type { RouteContext } from '@atlassian/react-resource-router';

type Props = RouteContext;

export const LegacyPageWithTeamProfileCardView = (props: Props) => (
	<>
		<LegacyPage {...props} />

		<AsyncOpenHelpPanelLinkThroughEventListener />

		<AsyncLegacyTeamProfileCard />
	</>
);
