import { legacyPageEntrypoint } from '@atlassian/jira-router-routes-legacy-page-entrypoint/src/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsProjects from '@atlassian/jira-navigation-apps-sidebar-global-settings-projects/src/async.tsx';
import { createLegacyEntry } from '@atlassian/jira-route-entry/src/createLegacyEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { globalSettingsProjectsCategoriesEditRoute } from '@atlassian/jira-router-routes-legacy-global-settings-projects-categories-edit-routes/src/globalSettingsProjectsCategoriesEditRoute.tsx';

const pageEntryPoint = createPageEntryPoint({
	main: legacyPageEntrypoint,
});

export const globalSettingsProjectsCategoriesEditRouteEntry = createLegacyEntry(
	globalSettingsProjectsCategoriesEditRoute,
	() => ({
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_PROJECTS_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsProjects,
		},

		resources: [navigationSidebarGlobalResource],
		entryPoint: () => pageEntryPoint,
	}),
);
