import { legacyPageEntrypoint } from '@atlassian/jira-router-routes-legacy-page-entrypoint/src/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { MENU_ID, SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { getNavigationSidebarGlobalSettingsResources } from '@atlassian/jira-navigation-apps-resources/src/controllers/forge/index.tsx';
import AsyncSidebarGlobalSettingsApps from '@atlassian/jira-navigation-apps-sidebar-global-settings-apps/src/async.tsx';
import { createLegacyEntry } from '@atlassian/jira-route-entry/src/createLegacyEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { addonsEmceePublicRoute } from '@atlassian/jira-router-routes-legacy-addons-emcee-public-routes/src/addonsEmceePublicRoute.tsx';

const pageEntryPoint = createPageEntryPoint({
	main: legacyPageEntrypoint,
	topNavigationMenuId: MENU_ID.ADDONS,
});

export const addonsEmceePublicRouteEntry = createLegacyEntry(addonsEmceePublicRoute, () => ({
	layout: globalSettingsLayout,

	navigation: {
		sidebarId: SIDEBAR_ID.GLOBAL_APPS_SETTINGS,
		menuId: MENU_ID.ADDONS,
		sidebar: AsyncSidebarGlobalSettingsApps,
	},

	resources: [navigationSidebarGlobalResource, ...getNavigationSidebarGlobalSettingsResources()],
	entryPoint: () => pageEntryPoint,
}));
