import { legacyPageEntrypoint } from '@atlassian/jira-router-routes-legacy-page-entrypoint/src/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarFilters from '@atlassian/jira-navigation-apps-sidebar-filters/src/async.tsx';
import { createLegacyEntry } from '@atlassian/jira-route-entry/src/createLegacyEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { legacyGinSubscriptionsRoute } from '@atlassian/jira-router-routes-legacy-gin-subscriptions-routes/src/legacyGinSubscriptionsRoute.tsx';

const pageEntryPoint = createPageEntryPoint({
	main: legacyPageEntrypoint,
	topNavigationMenuId: MENU_ID.FILTERS,
});

export const legacyGinSubscriptionsRouteEntry = createLegacyEntry(
	legacyGinSubscriptionsRoute,
	() => ({
		layout: globalLayout,

		navigation: {
			menuId: MENU_ID.FILTERS,
			sidebar: AsyncSidebarFilters,
		},

		resources: [navigationSidebarGlobalResource],
		entryPoint: () => pageEntryPoint,
	}),
);
