import { fg } from '@atlassian/jira-feature-gating';
import { createSessionStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/session-storage/index.tsx';

// TODO: JFP-2294 Add a prefix to identify each app and please update the same in test as well.
const sessionStorageProvider = createSessionStorageProvider('');

const SHOW_NEW_EDITOR_SUCCESS_FLAG = 'SHOW_NEW_EDITOR_SUCCESS_FLAG';
const SHOW_OLD_EDITOR_ERROR_FLAG = 'SHOW_OLD_EDITOR_SUCCESS_FLAG';

// New editor success flag
const updateShowNewEditorSucccessFlag = (value: boolean) => {
	fg('jfp-vulcan-browser-storage-migration')
		? sessionStorageProvider.set(SHOW_NEW_EDITOR_SUCCESS_FLAG, value)
		: sessionStorage.setItem(SHOW_NEW_EDITOR_SUCCESS_FLAG, value.toString());
};

export const getShouldShowNewEditorSucccessFlag = () =>
	fg('jfp-vulcan-browser-storage-migration')
		? Boolean(sessionStorageProvider.get(SHOW_NEW_EDITOR_SUCCESS_FLAG))
		: Boolean(sessionStorage.getItem(SHOW_NEW_EDITOR_SUCCESS_FLAG));

export const setShowNewEditorSuccessFlag = () => {
	updateShowNewEditorSucccessFlag(true);
};

export const clearShowNewEditorSucccessFlag = () => {
	fg('jfp-vulcan-browser-storage-migration')
		? sessionStorageProvider.remove(SHOW_NEW_EDITOR_SUCCESS_FLAG)
		: sessionStorage.removeItem(SHOW_NEW_EDITOR_SUCCESS_FLAG);
};

// Old editor success flag
const updateShowOldEditorSucccessFlag = (value: boolean) => {
	fg('jfp-vulcan-browser-storage-migration')
		? sessionStorageProvider.set(SHOW_OLD_EDITOR_ERROR_FLAG, value)
		: sessionStorage.setItem(SHOW_OLD_EDITOR_ERROR_FLAG, value.toString());
};

export const getShouldShowOldEditorSucccessFlag = () =>
	fg('jfp-vulcan-browser-storage-migration')
		? Boolean(sessionStorageProvider.get(SHOW_OLD_EDITOR_ERROR_FLAG))
		: Boolean(sessionStorage.getItem(SHOW_OLD_EDITOR_ERROR_FLAG));

export const clearShowOldEditorSucccessFlag = () => {
	fg('jfp-vulcan-browser-storage-migration')
		? sessionStorageProvider.remove(SHOW_OLD_EDITOR_ERROR_FLAG)
		: sessionStorage.removeItem(SHOW_OLD_EDITOR_ERROR_FLAG);
};

export const setShowOldEditorSuccessFlag = () => {
	updateShowOldEditorSucccessFlag(true);
};
