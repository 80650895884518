import { legacyPageEntrypoint } from '@atlassian/jira-router-routes-legacy-page-entrypoint/src/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { globalSettingsLayout } from '@atlassian/jira-global-settings-layout/src/index.tsx';
import { SIDEBAR_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import AsyncSidebarGlobalSettingsIssues from '@atlassian/jira-navigation-apps-sidebar-global-settings-issues/src/async.tsx';
import { createLegacyEntry } from '@atlassian/jira-route-entry/src/createLegacyEntry.tsx';
import { navigationSidebarGlobalResource } from '@atlassian/jira-router-resources-navigation-sidebar-global/src/index.tsx';
import { globalSettingsIssuesFieldSchemesConfigureRoute } from '@atlassian/jira-router-routes-legacy-global-settings-issues-field-schemes-configure-routes/src/globalSettingsIssuesFieldSchemesConfigureRoute.tsx';

const pageEntryPoint = createPageEntryPoint({
	main: legacyPageEntrypoint,
});

export const globalSettingsIssuesFieldSchemesConfigureRouteEntry = createLegacyEntry(
	globalSettingsIssuesFieldSchemesConfigureRoute,
	() => ({
		layout: globalSettingsLayout,

		navigation: {
			sidebarId: SIDEBAR_ID.GLOBAL_ISSUES_SETTINGS,
			sidebar: AsyncSidebarGlobalSettingsIssues,
		},

		resources: [navigationSidebarGlobalResource],
		entryPoint: () => pageEntryPoint,
	}),
);
