import { legacyPageEntrypoint } from '@atlassian/jira-router-routes-legacy-page-entrypoint/src/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { MENU_ID } from '@atlassian/jira-navigation-apps-common/src/constants.tsx';
import { createLegacyEntry } from '@atlassian/jira-route-entry/src/createLegacyEntry.tsx';
import { dashboardsDirectoryRoute } from '@atlassian/jira-router-routes-legacy-dashboards-directory-routes/src/dashboardsDirectoryRoute.tsx';

const pageEntryPoint = createPageEntryPoint({
	main: legacyPageEntrypoint,
	topNavigationMenuId: MENU_ID.DASHBOARDS,
});

export const dashboardsDirectoryRouteEntry = createLegacyEntry(dashboardsDirectoryRoute, () => ({
	layout: globalLayout,

	navigation: {
		menuId: MENU_ID.DASHBOARDS,
	},

	entryPoint: () => pageEntryPoint,
}));
