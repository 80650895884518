import { legacyPageEntrypoint } from '@atlassian/jira-router-routes-legacy-page-entrypoint/src/entrypoint.tsx';
import { createPageEntryPoint } from '@atlassian/jira-create-route-entry-point/src/index.tsx';
import { globalLayout } from '@atlassian/jira-global-layout/src/index.tsx';
import { createLegacyEntry } from '@atlassian/jira-route-entry/src/createLegacyEntry.tsx';
import { legacyGinIssueEditRoute } from '@atlassian/jira-router-routes-legacy-gin-issue-edit-routes/src/legacyGinIssueEditRoute.tsx';

const pageEntryPoint = createPageEntryPoint({
	main: legacyPageEntrypoint,
});

export const legacyGinIssueEditRouteEntry = createLegacyEntry(legacyGinIssueEditRoute, () => ({
	layout: globalLayout,
	entryPoint: () => pageEntryPoint,
}));
