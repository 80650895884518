import type { InvariantJiraRoute } from '@atlassian/jira-route-types/src/InvariantJiraRoute.tsx';
import type {
	JiraLegacyRoute,
	JiraLegacyRouteOptions,
} from '@atlassian/jira-route-types/src/JiraLegacyRoute.tsx';
import type { RouteResources } from '@atlassian/react-resource-router/src/common/types.tsx';
import { createRouteEntry } from './createRouteEntry.tsx';

/**
 * Create a route entry for legacy pages.
 */
export const createLegacyEntry = createRouteEntry<
	InvariantJiraRoute,
	JiraLegacyRouteOptions<RouteResources>,
	JiraLegacyRoute<RouteResources>
>;
